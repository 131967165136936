<template>
    <div class="page-wrap pt-3">
        <div class="session-form-hold">
            <div>
                <v-progress-linear
                    :active="loading"
                    :indeterminate="loading"
                    absolute
                    top
                    color="white"
                />
            </div>
        </div>
    </div>
</template>
<script>

import { mapGetters, mapActions } from 'vuex'

export default {
    name: 'Login',
    metaInfo: {
    // title will be injected into parent titleTemplate
    // title: this.$t('users.login.title')
    },
    data() {
        return {
            loading: false
        }
    },
    computed: {
        ...mapGetters(['loggedInUser', 'error'])
    },
    created() {
        this.doAction();
    },
    methods: {
        ...mapActions(['loadProfile']),

        async doAction() {
            const token = this.$route.query.token;

            if (token?.length) {
                const result = await this.loadProfile(token);
                if (result === "OK") {
                    this.loading = true
                    this.$router.push('/')
                } else {
                    const error = result.error;
                    if (error === 'change_password') {
                        if (
                            await this.$refs.confirm.open(
                                this.$t('users.login.newPassMSG.title'),
                                this.$t('users.login.newPassMSG.msg'),
                                { noCancel: true }
                            )
                        ) {
                            const token = result.token;
                            //  "/app/sessions/pass-reset/?token="
                            this.$router.push('pass-reset/?token=' + token);
                        }
                    } else {
                        this.serverError = this.$t(`errors.${error}`);
                    }
                }
            } else {
                console.log("Token don't found");
            }
        }
    },
    watch: {
        error(val) {
            if (val != null) {
                // this.makeToast("warning", val.message);
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.page-wrap {
  //background-color: #242939 !important;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100%;

}

.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}

</style>
